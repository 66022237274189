<template>
  <v-main class="pt-0 fill-height">
    <v-container class="pa-0" fluid fill-height>
      <v-layout child-flex>
        <v-card class="pa-0" color="transparent">
          <v-card-text
              class="px-0"
          >
            <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                :expanded="expanded"
                item-key="index"
                show-expand
                @item-expanded="fetchCharts"
                sort-by="calories"
                class="elevation-1"
            >
              <template v-slot:top>
                <v-toolbar
                    flat
                >
                  <v-toolbar-title>Patients</v-toolbar-title>
                  <v-divider
                      class="mx-4"
                      inset
                      vertical
                  ></v-divider>
                  <v-spacer></v-spacer>
                  <add-patient
                      :presetMetrics="metrics"
                      @update="addPatient"
                  ></add-patient>
                </v-toolbar>
                <v-row>
                  <v-col class="px-6 pb-5">
                    <v-text-field
                        v-model="search"
                        color="secondary"
                        label="Patient Search"
                        hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </template>
              <template
                  v-slot:expanded-item="{ headers, item }"
              >
                <td :colspan="headers.length">
                  <v-row
                      v-if="item.charts"
                  >
                    <v-col>
                      <v-row
                          v-if="item.charts.length > 0"
                          class="pt-4"
                      >
                        <v-col
                            v-for="(chart, i) in item.charts"
                            :key="i"
                            cols="4"
                        >
                          <line-chart
                              :data="chart"
                              height="200px"
                          >
                          </line-chart>
                        </v-col>
                      </v-row>
                      <v-row
                          v-else
                      >
                        <v-col>
                          Charts will appear here if this patient submitted data.
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row
                    v-else
                  >
                    <v-col>
                      Charts will appear here if this patient submitted data.
                    </v-col>
                  </v-row>
                </td>
              </template>
              <template v-slot:item.last_response="{ item }">
                <span v-if="item.last_response">{{item.last_response}}</span>
                <span v-else>N/A</span>
              </template>
              <template v-slot:item.number_of_responses="{ item }">
                <span v-if="item.number_of_responses">{{item.number_of_responses}}</span>
                <span v-else>0</span>
              </template>
              <template v-slot:item.patient_status="{ item }">
                <patient-status
                    :patient="item"
                    @update="updateRow"
                ></patient-status>
              </template>
              <template v-slot:item.notification_status="{ item }">
                <notification-status
                    :patient="item"
                    @update="updateRow"
                >
                </notification-status>
              </template>
              <template v-slot:item.metrics="{ item }">
                <metrics-modal
                  :patient="item">
                </metrics-modal>
              </template>
              <template v-slot:no-data>
                You are ready to add new patients.
              </template>
              <template v-slot:item.actions="{ item }">
                <edit-patient
                    :patient="item"
                    @update="updateRow"
                >
                </edit-patient>
                <manual-message
                    :messages="messageTypes"
                    :patient="item"
                ></manual-message>
                <delete-patient-confirmation
                    @update="removeRow"
                    :patient="item"
                ></delete-patient-confirmation>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions } from 'vuex'

import NotificationStatus from "../components/dashboard/NotificationStatus";
import PatientStatus from "../components/dashboard/PatientStatus";

import { mdiPen } from '@mdi/js';
import DeletePatientConfirmation from "../components/dashboard/DeletePatientConfirmationl";
import EditPatient from "../components/dashboard/EditPatient";
import AddPatient from "../components/dashboard/AddPatient";
import ManualMessage from "../components/dashboard/ManualMessage";
import LineChart from "../components/dashboard/LineChart";
import MetricsModal from "../components/dashboard/MetricsModal";

export default {
  name: 'Dashboard',
  components: {
    MetricsModal,
    LineChart,
    ManualMessage,
    AddPatient,
    EditPatient,
    DeletePatientConfirmation, PatientStatus, NotificationStatus },
  data: () => ({
    penIcon: mdiPen,
    dialog: false,
    dialogDelete: false,
    search: '',
    messageTypes: [],
    expanded: [],
    metrics: [],
    colors: ['#0D2E41', '#00798A', '#F8AF2C', '#C27691', '#D9734E', '#E09C4C', '#87D4F5', '#E09C4C'],
    headers: [
      { text: '', value: 'data-table-expand' },
      {
        text: 'First Name',
        align: 'start',
        sortable: true,
        value: 'first_name',
      },
      {
        text: 'Last Name',
        align: 'start',
        sortable: true,
        value: 'last_name',
      },
      {
        text: 'Email',
        align: 'start',
        width: 150,
        sortable: true,
        value: 'email',
      },
      {
        text: 'Monthly Responses',
        align: 'center',
        width: 120,
        sortable: true,
        value: 'number_of_responses',
      },
      {
        text: 'Last Response',
        align: 'center',
        width: 140,
        sortable: true,
        value: 'last_response',
      },
      {
        text: 'Phone Number',
        align: 'start',
        sortable: true,
        value: 'phone',
      },
      {
        text: 'Patient Status',
        align: 'center',
        sortable: true,
        value: 'patient_status',
      },
      {
        text: 'Notification Status',
        align: 'center',
        sortable: true,
        value: 'notification_status',
      },
      {
        text: 'Metrics',
        align: 'center',
        sortable: true,
        value: 'metrics',
      },
      { text: 'Actions', align: 'center', value: 'actions', sortable: false },
    ],
    items: [],
    defaultItem: {
      phone: '',
      email: '',
      patient_status: '',
      notification_status: '',
      metrics: [],
      index: 0
    },
  }),
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.close()
    },
  },
  async created () {
    try {
      const accessToken = await this.$auth.getTokenSilently()

      await this.initialize()

      this.messageTypes = await this.fetchMessageTypes({
        accessToken: accessToken
      })

      const settings = await this.fetchUserSettings({
        authToken: accessToken
      })

      if (settings) {
        if (settings.user_metadata) {
          if (settings.user_metadata.metrics) {
            this.metrics = settings.user_metadata.metrics
          }
        }
      }
    } catch(error) {
      console.log(error)
      this.error = 'Looks like we encountered some problems while fetching your information due to our server outage.'
    }
  },

  methods: {
    ...mapActions(['fetchAllPatients', 'fetchMessageTypes', 'fetchPatientData', 'fetchUserSettings']),
    async initialize () {
      try {
        const accessToken = await this.$auth.getTokenSilently()

        this.fetchAllPatients({
          accessToken: accessToken
        })
        .then((results) => {
          this.items = results
        })
            .catch((error) => {
              console.log(error)
            })

      } catch (error) {
        console.log(error)
      }
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    addPatient (patient) {
      this.items.unshift(patient)
    },
    removeRow (patient) {
      const patientIndex = this.items.findIndex(item => item.index === patient.index)

      if (patientIndex > -1) {
        this.items.splice(patientIndex, 1)
      }
    },
    updateRow (patient) {
      this.items = this.items.map((item) => {
        if (item.index === patient.index) {
          item = patient
        }

        return item
      })
    },
    async fetchCharts (patient) {

      if (patient.item && patient.value) {
        const patientIndex = this.items.findIndex(item => item.index === patient.item.index)

        try {
          let charts = []

          const accessToken = await this.$auth.getTokenSilently()

          const chartData = await this.fetchPatientData({
            index: patient.item.index,
            accessToken: accessToken
          })

          if (chartData) {
            let i = 0
            for (let data in chartData) {
              charts.push({
                name: data,
                data: {
                  labels: chartData[data].labels,
                  datasets: [{
                    data: chartData[data].datasets,
                    backgroundColor: this.colors[i],
                    cubicInterpolationMode: 'monotone',
                    fill: true
                  }]
                }
              })

              i += 1
            }
          }

          patient.item.charts = charts

          this.items.splice(patientIndex, 1, patient.item)
        } catch (error) {
          console.log(error)
          this.error = 'Patient data was not fetched due to our server outage.'
        }
      }

    }
  },
}
</script>

<style>
  .no-uppercase {
    text-transform: none !important;
    text-transform: unset !important;
  }
  v-data-table>.v-data-table__wrapper>table>tbody>tr>td, .v-data-table>.v-data-table__wrapper>table>tfoot>tr>td, .v-data-table>.v-data-table__wrapper>table>thead>tr>td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
