<template>
  <v-combobox
      class="mb-4"
      v-model="selectedMetrics"
      :items="availableMetrics"
      item-value="value"
      item-text="name"
      item-color="secondary"
      color="secondary"
      label="Patient Metrics"
      hide-details
      small-chips
      multiple
  ></v-combobox>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: 'Metrics',
  props: {
    presetMetrics: {
      type: Array,
      default: () => {
        return []
      }
    },
    patient: {
      type: Object,
      default: () => {
        return {
          phone: '',
          email: '',
          metrics: [],
          patient_status: '',
          notification_status: '',
          index: 0
        }
      }
    }
  },
  data: () => ({
    isLoading: false,
    availableMetrics: [],
    selectedMetrics: []
  }),
  async created () {
    try {
      const authToken = await this.$auth.getTokenSilently();

      this.availableMetrics = await this.fetchMetrics({
        authToken: authToken
      })

      if (this.patient.index > 0) {
        if (this.patient.metrics) {
          this.selectedMetrics = this.patient.metrics.map((metric) => {
            const value = this.availableMetrics.filter(availableMetric => {
              return metric === availableMetric.value
            })

            return value[0]
          })
        }
      } else {
        const settings = await this.fetchUserSettings({
          authToken: authToken
        })

        if (settings) {
          if (settings.user_metadata) {
            if (settings.user_metadata.metrics) {
              this.selectedMetrics = settings.user_metadata.metrics
            }
          }
        }
      }

      this.$emit('update', this.selectedMetrics)
    } catch (error) {
      console.log(error)

      this.setAlerts({
        active: true,
        color: 'error',
        message: 'We encountered an error while fetching your metrics.'
      })
    }
  },
  methods: {
    ...mapActions(['setAlerts', 'fetchMetrics', 'fetchUserSettings']),
  },
  watch: {
    selectedMetrics (newVal) {
      this.$emit('update', newVal)
    },
    patient (newVal) {
      this.selectedMetrics = newVal.metrics.map((metric) => {
        return {
          name: metric[0].toUpperCase() + metric.substring(1),
          value: metric,
          disabled: false
        }
      })
    },
  },
}
</script>
