<template>
  <v-dialog
      v-model="dialog"
      max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          icon
          v-bind="attrs"
          v-on="on"
      >
        <v-icon
            small
            color="error">
          {{ trashCanIcon }}
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Permanently Delete the Patient</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row
              v-if="error"
          >
            <v-col
                class="px-0"
            >
              <v-alert
                  class="ma-0"
                  border="left"
                  color="error"
                  dark
              >
                <p
                    class="ma-0">{{ error }}</p>
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                class="pa-0"
            >
              <p class="ma-0">You are about to delete a patient with this phone number: {{ patient.phone }}</p>
              <p class="ma-0">All patient data will be removed.</p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="error"
            text
            @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
            :loading="isLoading"
            :disabled="isLoading"
            color="secondary"
            @click="deletePatient"
        >
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { mdiTrashCan } from '@mdi/js';

export default {
  name: 'DeletePatientConfirmation',
  props: {
    patient: {
      type: Object,
      default: () => {
        return {
          phone: '',
          email: '',
          patient_status: '',
          notification_status: '',
          index: 0
        }
      }
    }
  },
  data: () => ({
    trashCanIcon: mdiTrashCan,
    error: null,
    dialog: false,
    isLoading: false
  }),
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  methods: {
    ...mapActions(['deletePatientById']),
    close () {
      this.dialog = false
    },
    async deletePatient () {
      this.error = null

      if (this.patient) {
        if (this.patient.index) {
          if (Number.isInteger(this.patient.index)) {
            try {
              const accessToken = await this.$auth.getTokenSilently()

              await this.deletePatientById({
                accessToken: accessToken,
                index: this.patient.index
              })
              this.close()
              this.$emit('update', {
                index: this.patient.index
              })
            } catch (error) {
              console.log(error)
              this.error = 'Patient information was not saved due to our server outage.'
            }
          } else {
            this.error = 'Patient was not recognized.'
          }
        } else {
          this.error = 'Patient was not recognized.'
        }
      } else {
        this.error = 'Patient was not recognized.'
      }
    },
  },
}
</script>
