<template>
  <v-dialog
      v-model="dialog"
      max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          icon
          v-bind="attrs"
          v-on="on"
      >
        <v-icon
            small
        >
          {{ penIcon }}
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Edit Patient Information</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row
              v-if="error"
          >
            <v-col
                class="px-0"
            >
              <v-alert
                  class="ma-0"
                  border="left"
                  color="error"
                  dark
              >
                <p
                    class="ma-0">{{ error }}</p>
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                lg="6"
                class="pa-0 pr-lg-2"
            >
              <v-text-field
                  :disabled="isLoading"
                  v-model="firstName"
                  label="First Name"
                  type="text"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                lg="6"
                class="pa-0 pl-lg-2"
            >
              <v-text-field
                  :disabled="isLoading"
                  v-model="lastName"
                  label="Last Name"
                  type="text"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                class="pa-0"
            >
              <v-text-field
                  :disabled="isLoading"
                  v-model="email"
                  label="Email"
                  type="email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                class="pa-0"
            >
              <v-text-field
                  :disabled="isLoading"
                  v-model="phone"
                  hide-details
                  label="Phone Number"
                  type="number"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="error"
            text
            @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
            :loading="isLoading"
            :disabled="isLoading"
            color="secondary"
            @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { mdiPen } from '@mdi/js';

export default {
  name: 'EditPatient',
  props: {
    patient: {
      type: Object,
      default: () => {
        return {
          first_name: '',
          last_name: '',
          phone: '',
          email: '',
          patient_status: '',
          notification_status: '',
          index: 0
        }
      }
    }
  },
  data: () => ({
    penIcon: mdiPen,
    isLoading: false,
    firstName: '',
    lastName: '',
    dialog: false,
    email: '',
    phone: '',
    error: null
  }),
  created () {
    this.firstName = this.patient.first_name
    this.lastName = this.patient.last_name
    this.email = this.patient.email
    this.phone = this.patient.phone
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  methods: {
    ...mapActions(['updatePatientById']),
    close () {
      this.dialog = false
      this.firstName = ''
      this.lastName = ''
      this.phone = ''
      this.email = ''
      this.error = null
    },
    async save () {
      this.error = null

      if (this.phone) {
        let phone = this.phone.replace(/\D/g,'')
        if (phone.length >= 10 && phone.length < 14) {
          try {
            const accessToken = await this.$auth.getTokenSilently()

            const patient = {
              index: this.patient.index,
              first_name: this.firstName,
              last_name: this.lastName,
              phone: this.phone,
              email: this.email,
              accessToken: accessToken
            }

            const newAttrs = await this.updatePatientById({
              ...patient
            })

            this.$emit('update', newAttrs)
            this.close()
          } catch (error) {
            console.log(error)
            this.error = 'Patient information was not saved due to our server outage.'
          }
        } else {
          this.error = 'Please enter a valid phone number.'
        }
      } else {
        this.error = 'Please enter a valid phone number.'
      }
    },
  },
}
</script>
