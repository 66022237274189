<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            class="no-uppercase"
            color="transparent"
            depressed
            small
            block
            :disabled="isLoading || patient.patient_status === 'inactive'"
            :loading="isLoading"
            v-bind="attrs"
            v-on="on"
        >
          Metrics
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5">
          Metrics
        </v-card-title>

        <v-card-text>
          <p class="mt-8 mb-2">Your patients needs to track at least one metric.</p>
          <metrics
              :patient="patient"
              @update="metricsHandler"
          ></metrics>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="error"
              text
              @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
              :loading="isLoading"
              :disabled="isLoading"
              color="secondary"
              @click="update"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Metrics from "./Metrics";

export default {
  name: 'MetricsModal',
  components: { Metrics },
  props: {
    patient: {
      type: Object,
      default: () => {
        return {
          phone: '',
          email: '',
          metrics: [],
          patient_status: '',
          notification_status: '',
          index: 0
        }
      }
    }
  },
  data: () => ({
    dialog: false,
    isLoading: false,
    metrics: []
  }),
  methods: {
    ...mapActions(['updatePatientMetrics']),
    metricsHandler (metrics) {
      this.metrics = metrics.reduce((accumulator, metric) => {
        if (metric) {
          if (metric.value) {
            accumulator.push(metric.value)
          }
        }

        return accumulator
      }, [])
    },
    async update () {
      this.dialog = false
      this.isLoading = true
      this.error = null

      console.log('this.metrics: ', this.metrics)
      if (this.metrics.length > 0) {
        if (this.patient) {
          if (this.patient.index) {
            if (Number.isInteger(this.patient.index)) {
              try {
                const patient = {
                  index: this.patient.index,
                  metrics: this.metrics
                }

                const accessToken = await this.$auth.getTokenSilently()

                await this.updatePatientMetrics({
                  ...patient,
                  accessToken: accessToken
                })

                this.isLoading = false
              } catch (error) {
                console.log(error)
                this.isLoading = false
                this.error = 'Patient information was not saved due to our server outage.'
              }
            } else {
              this.isLoading = false
              this.error = 'Patient was not recognized.'
            }
          } else {
            this.isLoading = false
            this.error = 'Patient was not recognized.'
          }
        } else {
          this.isLoading = false
          this.error = 'Patient was not recognized.'
        }
      } else {
        this.isLoading = false
        this.error = 'Patient needs to be assigned at least one metric.'
      }
    },
  },
}
</script>
