<template>
  <div>
    <label><strong>{{ data.name.toUpperCase() }}</strong></label>
    <p
        class="ma-0"
        v-if="data.data.labels.length < 1"
    >
      We are waiting for you to submit your data, once we have at least 2 submissions, you'll see a graph here.
    </p>
    <p
        class="ma-0"
        v-else-if="data.data.labels.length === 1"
    >
      We have your data point, once we have at least 2 submissions, you'll see a graph here.
    </p>
    <simple-line
        v-else
        class="py-4"
        :style="{ maxHeight: height, maxWidth: width }"
        :chart-data="data.data"
    ></simple-line>
  </div>
</template>

<script>
import SimpleLine from "../common/charts/SimpleLine";

export default {
  name: 'LineChart',
  components: { SimpleLine },
  data: () => ({
    statusMessage: ''
  }),
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          data: {
            labels: []
          }
        }
      }
    },
    height: {
      type: String,
      default: () => {
        return '100%'
      }
    },
    width: {
      type: String,
      default: () => {
        return '100%'
      }
    }
  },
  computed: {
    show () {
      if (this.data) {
        if (this.data.data) {
          if (this.data.data.labels) {
            if (this.data.data.labels.length > 1) {
              return true
            } else {
              return false
            }
          } else {
            return false
          }
        } else {
          return false
        }
      } else {
        return false
      }
    },
  }
}
</script>
