<template>
  <v-dialog
      v-model="dialog"
      max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="info"
          icon
          v-bind="attrs"
          v-on="on"
      >
        <v-icon
            small
        >
          {{ sendIcon }}
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Send Message</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row
              v-if="error"
          >
            <v-col
                class="px-0"
            >
              <v-alert
                  class="ma-0"
                  border="left"
                  color="error"
                  dark
              >
                <p
                    class="ma-0">{{ error }}</p>
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                class="pa-0"
            >
              <p class="ma-0">You are about to send a message to <strong>{{ patient.phone }}</strong> manually, bypassing our automated system.<br/><br/>
              This patient will continue receiving message following the regular schedule while skipping the message you are about to send.<br/>
              For example, if you manually trigger a message on a Tuesday at 10:00am, for the message that this patient typically receives at 11:00am and 7:00pm,
              this patient will no longer receive a duplicate message at 11:00am, but will automatically receive a message at 7:00pm.</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                class="pa-0"
            >
              <v-divider
                  class="mt-6"
              ></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                class="pa-0"
            >
              <v-select
                  color="secondary"
                  v-model="message"
                  :items="availableMessages"
                  item-value="template"
                  item-text="name"
                  item-color="secondary"
                  label="Messages"
                  return-object
              ></v-select>
            </v-col>
          </v-row>
          <v-row
              v-if="message.body"
          >
            <v-col
                cols="12"
                class="pa-0"
            >
              <p class="ma-0">
                <strong>Preview without variables:</strong>
                <br/>
                {{ message.body }}
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="error"
            text
            @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
            :loading="isLoading"
            :disabled="isLoading"
            color="secondary"
            @click="send"
        >
          Send
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { mdiSend } from '@mdi/js';

export default {
  name: 'ManualMessage',
  props: {
    patient: {
      type: Object,
      default: () => {
        return {
          phone: '',
          email: '',
          patient_status: '',
          notification_status: '',
          index: 0
        }
      }
    },
    messages: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data: () => ({
    sendIcon: mdiSend,
    message: {},
    isLoading: false,
    dialog: false,
    error: null
  }),
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  computed: {
    availableMessages () {
      if (this.patient.patient_status === 'inactive' || this.patient.notification_status === 'doctor_stopped' || this.patient.notification_status === 'patient_stopped') {
        return this.messages.filter(message => message.template === 'introduction_part_1')
      } else {
        return this.messages
      }
    }
  },
  methods: {
    ...mapActions(['manuallySendMessage']),
    close () {
      this.dialog = false
      this.error = null
    },
    async send () {
      this.error = null

      if (this.message) {
        if (this.message.template) {
          try {
            const accessToken = await this.$auth.getTokenSilently()

            const patient = {
              index: this.patient.index,
              message: this.message.template,
              accessToken: accessToken
            }

            await this.manuallySendMessage({
              ...patient
            })

            this.close()
          } catch (error) {
            console.log(error)
            this.error = 'Patient did not receive the message due to our server outage.'
          }
        } else {
          this.error = 'Please select a valid message.'
        }
      } else {
        this.error = 'Please select a valid message.'
      }
    },
  },
}
</script>
