<template>
  <v-dialog
      v-model="dialog"
      max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="secondary"
          v-bind="attrs"
          v-on="on"
      >
        New Patient
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">New Patient</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row
              v-if="error"
          >
            <v-col
                class="px-0"
            >
              <v-alert
                  class="ma-0"
                  border="left"
                  color="error"
                  dark
              >
                <p
                    class="ma-0">{{ error }}</p>
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                lg="6"
                class="pa-0 pr-lg-2"
            >
              <v-text-field
                  :disabled="isLoading"
                  v-model="firstName"
                  color="secondary"
                  label="First Name"
                  type="text"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                lg="6"
                class="pa-0 pl-lg-2"
            >
              <v-text-field
                  :disabled="isLoading"
                  v-model="lastName"
                  color="secondary"
                  label="Last Name"
                  type="text"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                class="pa-0"
            >
              <metrics
                  :presetMetrics="presetMetrics"
                  @update="metricsHandler"
              ></metrics>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                class="pa-0"
            >
              <v-text-field
                  :disabled="isLoading"
                  v-model="email"
                  color="secondary"
                  label="Email"
                  type="email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                class="pa-0"
            >
              <v-text-field
                  :disabled="isLoading"
                  v-model="phone"
                  color="secondary"
                  hide-details
                  label="Phone Number"
                  type="number"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="error"
            text
            @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
            :loading="isLoading"
            :disabled="isLoading"
            color="secondary"
            @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import Metrics from "./Metrics";

export default {
  name: 'AddPatient',
  components: {Metrics},
  props: {
    presetMetrics: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data: () => ({
    isLoading: false,
    dialog: false,
    firstName: '',
    lastName: '',
    metrics: [],
    loadedMetrics: [],
    email: '',
    phone: '',
    error: null
  }),
  watch: {
    dialog (val) {
      val || this.close()
    }
  },
  methods: {
    ...mapActions(['insertPatient', 'fetchAllPatients']),
    close () {
      this.dialog = false
      this.firstName = ''
      this.lastName = ''
      this.phone = ''
      this.email = ''
      this.error = null
    },
    metricsHandler (metrics) {
      this.metrics = metrics.reduce((accumulator, metric) => {
        if (metric) {
          if (metric.value) {
            accumulator.push(metric.value)
          }
        }

        return accumulator
      }, [])
    },
    async save () {
      this.error = null

      try {
        const accessToken = await this.$auth.getTokenSilently()

        this.fetchAllPatients({
          accessToken: accessToken
        })
            .then((results) => {
              this.items = results
            })
            .catch(() => {
              this.error = 'Patients were not fetched due to our server outage.'
            })

      } catch (error) {
        console.log(error)
        this.error = 'Patients were not fetched due to our server outage.'
      }

      if (this.phone) {
        let phone = this.phone.replace(/\D/g,'')
        if (phone.length >= 10 && phone.length < 14) {
          try {
            const patient = {
              first_name: this.firstName,
              last_name: this.lastName,
              phone: this.phone,
              email: this.email,
              metrics: this.metrics
            }

            const accessToken = await this.$auth.getTokenSilently()

            const newAttrs = await this.insertPatient({
              ...patient,
              accessToken: accessToken
            })

            this.$emit('update', newAttrs)
            this.close()
          } catch (error) {
            console.log(error)
            this.error = 'Patient information was not saved due to our server outage.'
          }
        } else {
          this.error = 'Please enter a valid phone number.'
        }
      } else {
        this.error = 'Please enter a valid phone number.'
      }
    },
  },
}
</script>
