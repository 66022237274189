<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          class="no-uppercase"
          color="transparent"
          depressed
          small
          block
          :disabled="isLoading || patient.patient_status === 'inactive'"
          :loading="isLoading"
          v-bind="attrs"
          v-on="on"
      >
        {{ currentSelection }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item
          v-for="(item, index) in items"
          :key="index"
          :disabled="item.disabled"
          @click="selected(item)"
      >
        <v-list-item-title>{{ item.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'NotificationStatus',
  props: {
    patient: {
      type: Object,
      default: () => {
        return {
          phone: '',
          email: '',
          patient_status: '',
          notification_status: '',
          index: 0
        }
      }
    }
  },
  data: () => ({
    isLoading: false,
    items: [
      { name: 'Active', value: 'active', disabled: false },
      { name: 'Doctor Stopped', value: 'doctor_stopped', disabled: false },
      { name: 'Patient Stopped', value: 'patient_stopped', disabled: true  }
    ],
  }),
  computed: {
    currentSelection () {
      return this.items.reduce((acc, item) => {
        if (item.value === this.patient.notification_status) {
          acc = item.name
        }

        return acc
      }, '')
    }
  },
  methods: {
    ...mapActions(['updateNotificationStatusById']),
    async selected (item) {
      this.isLoading = true
      this.error = null

      if (this.patient) {
        if (this.patient.index) {
          if (Number.isInteger(this.patient.index)) {
            try {
              const patient = {
                index: this.patient.index,
                notificationStatus: item.value
              }

              const accessToken = await this.$auth.getTokenSilently()

              const newAttrs = await this.updateNotificationStatusById({
                ...patient,
                accessToken: accessToken
              })

              this.isLoading = false
              this.$emit('update', newAttrs)
            } catch (error) {
              console.log(error)
              this.isLoading = false
              this.error = 'Patient information was not saved due to our server outage.'
            }
          } else {
            this.isLoading = false
            this.error = 'Patient was not recognized.'
          }
        } else {
          this.isLoading = false
          this.error = 'Patient was not recognized.'
        }
      } else {
        this.isLoading = false
        this.error = 'Patient was not recognized.'
      }
    },
  },
}
</script>
