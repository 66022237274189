<script>
import { Line, mixins } from 'vue-chartjs'
import moment from 'moment'

const { reactiveProp } = mixins

export default {
  extends: Line,
  data: () => ({
    options: {
      elements: {
        point:{
          radius: 0
        }
      },
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: -5,
          right: 0,
          top: 0,
          bottom: 0
        }
      },
      tooltips: {
        enabled: true
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          gridLines: {
            display:false
          },
          ticks:{
            display: true,
            autoSkip: true,
            maxTicksLimit: 2,
            maxRotation: 90,
            minRotation: 90,
            fontColor: 'white',
            padding: 0,
            callback: (value, index, values) => {
              const start = moment(values[values.length - 1])
              const end = moment(values[0])
              const selected = moment(value)
              if (selected.isValid()) {
                const duration = moment.duration(start.diff(end));
                const hours = duration.asHours();

                if (hours <= 24) {
                  return selected.format('HH:mm A')
                } else {
                  return selected.format('MM/DD/YY')
                }

              }
            }
          }
        }],
        yAxes: [{
          gridLines: {
            display:false
          },
          ticks: {
            display: true,
            fontColor: 'white',
            stepSize: 10
          }
        }]
      },
      responsive: true,
      plugins: {
        title: {
          display: false
        },
        animations: {
          tension: {
            duration: 500,
            easing: 'linear',
            from: 1,
            to: 0,
            loop: false
          }
        },
      }
    }
  }),
  mixins: [ reactiveProp ],
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
</script>
